import { MediaUpload } from '../pages/Admin/MediaUpload';

function MediaUploadRoute() {
  return <MediaUpload />;
}

export const Component = MediaUploadRoute;

export function clientLoader() {
  return null;
}
